import { useState } from "react"
import { GetServerSideProps } from "next"
import { I18nProvider } from "next-localization"

import { REQUEST_TYPE } from "@app/api/constants"
import { HOMEPAGE_DOMAINS } from "@app/constants"
import { GlobalStateProvider } from "@app/contexts"
import { fetchGalleries } from "@app/features/galleries/requests"
import { getPublicGalleries } from "@app/features/gallery/utils"
import { fetchGeneralSettings } from "@app/features/general-settings/requests"
import { Maintenance } from "@app/features/maintenance/components"
import { Meta } from "@app/features/meta/components"
import { HOMEPAGE_META_KEY } from "@app/features/meta/constants"
import { getMeta } from "@app/features/meta/utils"
import { BusinessCardModal } from "@app/features/photographer-contacts/business-card-modal/business-card-modal"
import { ContactPhotographer } from "@app/features/photographer-contacts/contact-photographer/contact-photographer"
import { Galleries, Header } from "@app/features/portfolio/components"
import { TagsSelector } from "@app/features/tags/components"
import { LOCALE } from "@app/localization/constants"
import { GalleryPageProps } from "@app/types"
import { Footer } from "@app/ui/footer"
import { useSetTheme } from "@app/utils"

type PortfolioPageProps = GalleryPageProps & {
  galleries: any
  generalSettings: object
  isDisplayHomePage: boolean
}

const PortfolioPage = (props: PortfolioPageProps) => {
  const { galleries, generalSettings, dictionary, locale, isDisplayHomePage } = props
  useSetTheme("light")
  // Стейт модалки
  const [isModalOpen, setIsModalOpen] = useState(false)
  // Функция для открытия модалки
  const openModal = () => {
    setIsModalOpen(true)
  }

  // Функция для закрытия модалки
  const closeModal = () => {
    setIsModalOpen(false)
  }

  if (isDisplayHomePage) return <Maintenance>Галереи Vigbo</Maintenance>

  return (
    <I18nProvider lngDict={dictionary} locale={locale}>
      <GlobalStateProvider value={generalSettings}>
        <Meta {...getMeta({ key: HOMEPAGE_META_KEY, settings: generalSettings })}></Meta>
        <Header />
        <TagsSelector galleries={galleries} />
        <Galleries galleries={galleries} />
        <Footer />
        {generalSettings["businessCardShow"] && (
          <>
            <ContactPhotographer handleContactClick={openModal} />
            <BusinessCardModal isOpen={isModalOpen} onClose={closeModal} generalSettings={generalSettings} />
          </>
        )}
      </GlobalStateProvider>
    </I18nProvider>
  )
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const domain: string = ctx.req.headers.host

  if (HOMEPAGE_DOMAINS.includes(domain)) {
    return {
      props: { isDisplayHomePage: true },
    }
  }

  const galleries = await fetchGalleries(domain, ctx)
  const generalSettings = await fetchGeneralSettings({ requestType: REQUEST_TYPE.SERVER, ctx, domain })

  if (!generalSettings || !galleries) {
    return {
      notFound: true,
    }
  }

  const locale = galleries.lang || LOCALE.DEFAULT
  const { default: dictionary = {} } = await import(`@app/localization/dictionares/${locale}.json`)

  const publicGalleries = getPublicGalleries(galleries)

  return {
    props: {
      galleries: publicGalleries,
      generalSettings,
      dictionary,
      locale,
    },
  }
}

export default PortfolioPage
