import { useState } from "react"

import { GeneralSettingsInterface } from "@app/features/general-settings/types/general-settings"
import { isClient, normalizeSiteURL } from "@app/utils"
import { isMobileDevice } from "@app/utils"
import { Modal } from "ui/modal"

import { BusinessCard } from "../business-card/business-card"

import styles from "./business-card-modal.module.scss"

type Props = {
  isOpen?: boolean
  onClose?: () => void
  generalSettings: GeneralSettingsInterface
}

const BusinessCardModal = ({ isOpen, onClose, generalSettings }: Props) => {
  const {
    name,
    phone,
    site,
    facebook,
    instagram,
    whatsapp,
    telegram,
    vkontakte,
    youtube,
    vimeo,
    photographyDirection,
  } = generalSettings

  const [isQrOpen, setIsQrOpen] = useState(false)

  const websiteUrl = site ? normalizeSiteURL(site).replace(/^www\./, "") : ""
  const isMobile = isClient ? isMobileDevice() : false

  const handleRedirect = (type: "whatsapp" | "telegram", value: string) => {
    const urls = {
      whatsapp: `https://wa.me/${value.replace(/[+/\s]/g, "")}`,
      telegram: `https://t.me/${value}`,
    }
    window.open(urls[type], "_blank", "noopener noreferrer")
  }

  const hasContacts = phone || whatsapp || telegram
  const hasSocialMedia = facebook || instagram || whatsapp || telegram || vkontakte || youtube || vimeo

  return (
    <Modal open={isOpen} onClose={onClose} isCrossIconVisible={!isQrOpen} modalClassName={styles["modal"]}>
      {name && !hasContacts && !site && !photographyDirection ? (
        <div className={styles["centered-content"]}>
          <h2 className={styles["title"]}>{name}</h2>
        </div>
      ) : (
        <BusinessCard
          generalSettings={generalSettings}
          websiteUrl={websiteUrl}
          hasSocialMedia={hasSocialMedia}
          handleRedirect={handleRedirect}
          isMobile={isMobile}
          isQrOpen={isQrOpen}
          onQrOpen={() => setIsQrOpen(true)}
          onQrClose={() => setIsQrOpen(false)}
        />
      )}
    </Modal>
  )
}

export { BusinessCardModal }
