import { useI18n } from "next-localization"
import clsx from "clsx"

import { Button } from "@app/ui/button/button"
import { VisitCardArrow } from "@app/ui/icons/visit-card-arrow"

import styles from "./contact-photographer.module.scss"

type Props = {
  handleContactClick: () => void
  isFavoritesOpen?: boolean
  className?: string
  isFotoInside?: boolean
}

const ContactPhotographer = ({ handleContactClick, isFavoritesOpen, isFotoInside, className }: Props) => {
  const i18n = useI18n()

  return (
    <Button
      className={clsx(
        styles["visit-card"],
        isFavoritesOpen && styles["favorites-open"],
        isFotoInside && styles["photo-inside-button"],
        className
      )}
      skin="light"
      onClick={handleContactClick}
    >
      <VisitCardArrow width={24} height={24} fill={"#000"} />
      <span className={clsx(styles["contact-text"], isFotoInside && styles["photo-inside-text"])}>
        {i18n.t("favoritesListPage.leaveACommentButton")}
      </span>
    </Button>
  )
}

export { ContactPhotographer }
