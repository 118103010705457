import { FC } from "react"
import { QRCodeCanvas } from "qrcode.react"
import { useI18n } from "next-localization"

import { Back } from "@app/ui/back"

import styles from "./photographer-qr.module.scss"

type Props = {
  name: string
  phone: string
  whatsapp: string
  telegram: string
  onBackClick: () => void
}

const QRCodeContact: FC<Props> = (props) => {
  const { name, phone, whatsapp, telegram, onBackClick } = props
  const i18n = useI18n()

  const generateVCard = (name: string, phone?: string, whatsapp?: string, telegram?: string) => {
    const primaryPhone = phone || whatsapp || telegram

    if (!name || !primaryPhone) {
      return ""
    }

    return [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:;${name};;;`,
      `FN:${name}`,
      phone ? `TEL;TYPE=CELL:${phone}` : "",
      whatsapp ? `TEL;TYPE=WORK,VOICE:${whatsapp}` : "",
      telegram ? `TEL;TYPE=HOME,VOICE:${telegram}` : "",
      "END:VCARD",
    ]
      .filter(Boolean)
      .join("\n")
  }

  return (
    <>
      <Back className={styles["back"]} text={i18n.t("common.back")} onClick={onBackClick} />
      <div className={styles["qr-code-body"]}>
        <p className={styles["copy-contact"]}>{i18n.t("visitCardModal.scan_qrcode")}</p>
        <QRCodeCanvas
          className={styles["qr-code"]}
          value={generateVCard(name || "", phone, whatsapp, telegram)}
          size={240}
          level={"H"}
          minVersion={1}
        />
      </div>
    </>
  )
}

export { QRCodeContact }
