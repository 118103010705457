import { FC, PropsWithChildren } from "react"

import { Button } from "@app/ui/button"

import styles from "./save-contacts.module.scss"

type Props = PropsWithChildren<{
  name: string
  phone?: string
  whatsapp?: string
  telegram?: string
}>

const SaveContactsButton: FC<Props> = (props) => {
  const { phone, name, whatsapp, telegram, children } = props
  const generateVCard = (name: string, phone?: string, whatsapp?: string, telegram?: string) => {
    if (!name) {
      return null
    }

    const primaryNumber = phone || whatsapp || telegram
    const numberType = phone ? "CELL" : whatsapp ? "WORK,VOICE" : "HOME,VOICE"

    if (!primaryNumber) {
      return null
    }

    return [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:;${name};;;`,
      `FN:${name}`,
      `TEL;TYPE=${numberType}:${primaryNumber}`,
      "END:VCARD",
    ]
      .filter(Boolean)
      .join("\n")
  }

  const downloadVCard = (vCardText: string, name: string) => {
    const element = document.createElement("a")
    const file = new Blob([vCardText], { type: "text/vcard;charset=utf-8" })
    element.href = URL.createObjectURL(file)
    element.download = `${name}.vcf`
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  const handleSaveContact = () => {
    const vCardText = generateVCard(name, phone, whatsapp, telegram)
    if (vCardText) {
      downloadVCard(vCardText, name)
    } else {
      return null
    }
  }

  return (
    <Button className={styles["copy-contact"]} skin="light" onClick={handleSaveContact}>
      {children}
    </Button>
  )
}

export { SaveContactsButton }
